import { useRef } from 'react'
import BannerProductoPersona from './BannerProductoPersona'
import CaracteristicasProductoPersona from './CaracteristicasProductoPersona'
import ContratarProductoPersona from './ContratarProductoPersona'
import FooterProd from '../layout/FooterProd'
import ProductosPersona from './ProductosPersona'
import PreguntasFrecuentesProductoPersona from './PreguntasFrecuentesProductoPersona'

const Index = () => {
  const ref = useRef(null);
  const irAPreguntasFrecuentes = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <BannerProductoPersona />
      <div style={{ backgroundColor: '#FFFBFA', overflowX: 'hidden' }}>
        <CaracteristicasProductoPersona />
        <ContratarProductoPersona irAPreguntasFrecuentes={() => irAPreguntasFrecuentes()}/>
        <ProductosPersona />
        <PreguntasFrecuentesProductoPersona refPreguntas={ref}  />
      </div>
    </>
  )
}

export default Index