import { Col, Row, Typography } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';


import prod_empresa from '../../assets/images/prod-empresa.png'
import contratarEmpresa from '../../assets/images/prod-empresa-mobile.png'


const { Title, Text } = Typography;
const ContratarProductosEmpresa = ({ irAPreguntasFrecuentes }) => {
  return (

      <Row className='row-caract'>
        <Col xs={0} sm={0} md={0} lg={0} xl={12} xxl={12}>
          <img src={prod_empresa} alt='producto persona' className='image-emp' />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} className='space-text'>
          <Text className='titulo-contrato' style={{  }}>¿Cómo contratar?</Text>
          <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
            <img src={contratarEmpresa} alt='producto-empresa' className='image-per' style={{ paddingTop: 16 }}/>
          </Col>
          {/*<Text style={{ fontSize: 20, color: '#515151', textAlign:'justify', fontFamily: 'Degular' }}>Regístrate en este portal web como Persona al ingresar los datos solicitados y comienza a contratar productos desde allí. Luego podrás administrar tus suscripciones con tu cuenta en la misma plataforma.</Text>*/}
          <Col span={24} className='paddin'>
            <Text className='text-contratar'>Regístrate en este portal web como Persona al ingresar los datos solicitados y comienza a contratar productos desde allí. Luego podrás administrar tus suscripciones con tu cuenta en la misma plataforma. </Text>
          </Col>
          <Col span={24}>
            <Text className='text-contratar'> Al final de esta sección en <Link className="questions hoverLink" onClick={irAPreguntasFrecuentes}>Preguntas frecuentes</Link>, encontrarás una guía de inicio con las instrucciones y funcionalidades a detalle de nuestro marketplace.</Text>
          </Col>
        </Col>
    </Row>
  )
}

export default ContratarProductosEmpresa