import React, { useState } from 'react';
import { Col, Row, Divider, Typography, Space, Menu, Button, Flex } from 'antd';
import { Link } from 'react-router-dom';

import { InstagramOutlined } from '@ant-design/icons';
import ModalTerminosCondiciones from '../modals/ModalTerminosCondiciones';
import { BiLogoFacebook, BiLogoLinkedin } from 'react-icons/bi';
import { useMenu } from './MenuContext';

import face from '../../assets/images/face.svg'
import linke from '../../assets/images/linkedin.svg'
import insta from '../../assets/images/instagram.svg'

import logo from '../../assets/logos/Brand.svg';

const { Text } = Typography;

const FooterProd = () => {

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { selectedPath } = useMenu();

  const matchRoute = (pattern, path) => {
    // Si la ruta comienza con el patrón (para manejar IDs y parámetros)
    return path.startsWith(pattern);
  };

  const isPathActive = (path) => {
    const pathMappings = {
      '/producto-persona': [
        '/producto-persona',
        '/detalle-producto-persona'
      ],
      '/producto-empresa': [
        '/producto-empresa',
        '/detalle-producto-empresa'
      ],
      'productos': [
        '/producto-persona',
        '/producto-empresa',
        '/detalle-producto-persona',
        '/detalle-producto-empresa'
      ],
      '/home': ['/home'],
      '/contacto': ['/contacto']
    };

    return pathMappings[path]?.some(pattern =>
      matchRoute(pattern, selectedPath)
    ) || false;
  };
  const getActiveStyle = (paths, isSubmenu = false) => {
    // Si paths es un string, convertirlo en array
    const pathArray = typeof paths === 'string' ? [paths] : paths;

    // Verificar si alguna de las rutas está activa
    const isActive = pathArray.some(path => isPathActive(path));

    // Verificar si es un producto o detalle de producto
    const isProductPath =
      selectedPath.includes('producto-') ||
      selectedPath.includes('detalle-producto-');

    return {
      color: (isActive || (isProductPath && pathArray.includes('productos'))) ? '#FF411C' : '#FFFBFA',
      fontWeight: isSubmenu && isActive ? 700 : 400, // Cambiar fontWeight solo si es un submenú activo
      paddingBottom: 5,
      transition: 'all 0.3s ease',
      hover: 'red'
    };
  };

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='footer-producto'>
      <div className='footer01'>
        <img src={logo} alt='imagen-bolsa' className='logo-footer' />
        <Col span={24}>
          <Divider style={{
            backgroundColor: '#FFFBFA',
            height: 1,
            marginTop: 12,
            marginBottom: 14
          }}></Divider>
        </Col>
        {/* <Row gutter={[16, 16]}>
          <Col xs={0} sm={0} md={24}>
            <Menu theme='dark' mode='horizontal' style={{ lineHeight: '64px', justifyContent: 'flex-start', textAlign: 'center', marginLeft: 4 }} className='menu-footer'>
              <Space size={60}>
                <Link to="/home">
                  <span className='option-menu-footer' style={{ ...getActiveStyle('/home'), fontWeight: 700 }}>Inicio</span>
                </Link>
                <span className='option-menu-footer' style={{ ...getActiveStyle(['productos', '/producto-persona', '/producto-empresa']), fontWeight: 700 }}>Productos</span>
                <Link to="/contacto">
                  <span className='option-menu-footer' style={{ ...getActiveStyle('/contacto'), fontWeight: 700 }}>Contacto</span>
                </Link>
              </Space>


            </Menu>
          </Col>
        </Row> */}
        <Row gutter={[16, 16]}>
          <Col xs={0} sm={0} md={24}>
            <Menu theme='dark' mode='horizontal' style={{ lineHeight: '64px', justifyContent: 'flex-start', textAlign: 'center', marginLeft: 4 }} className='menu-footer'>
              <Link to="/home">
                <span className='option-menu-footer' style={{ ...getActiveStyle('/home'), fontWeight: 700 }}>Inicio</span>
              </Link>
              <Link to="#">
                <span className='option-menu-footer productos-desktop' style={{ ...getActiveStyle(['productos', '/producto-persona', '/producto-empresa']) }}>Productos</span>
              </Link>
              <Link to="/contacto">
                <span className='option-menu-footer' style={{ ...getActiveStyle('/contacto'), fontWeight: 700, marginLeft: 60 }}>Contacto</span>
              </Link>
            </Menu>
          </Col>
        </Row>
        {/* parte abajo menu */}
        <Row gutter={[16, 16]}>
          <Col xs={0} sm={0} md={24}>
            <div className='submenu-list' style={{ marginTop: -15 }}>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to='/producto-persona'
                className='option-menu-footer'
                style={{
                  ...getActiveStyle('/producto-empresa', true),
                  color: selectedPath === '/producto-persona' ? '#FF411C' : '#FFFBFA',
                  fontFamily: 'Degular'

                }}
              >
                Persona
              </Link>
            </div>
          </Col>
          <Col xs={0} sm={0} md={24}>
            <div className='submenu-list' style={{ position: 'relative', top: -12, }}>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to='/producto-empresa'
                className='option-menu-footer'
                style={{
                  ...getActiveStyle('/producto-empresa', true),
                  color: selectedPath === '/producto-empresa' ? '#FF411C' : '#FFFBFA',
                  fontFamily: 'Degular',
                }}
              >
                Empresa
              </Link>
            </div>
          </Col>
        </Row>


        {/*Version mobile*/}
        <Row gutter={0}>
          <Col xs={24} sm={24} md={0}>
            <Menu
              theme='dark'
              mode='vertical'
              className='menu-footer'
              selectedKeys={[
                isPathActive('/home') ? 'home' : '',
                isPathActive('/producto-persona') ? 'persona' : '',
                isPathActive('/producto-empresa') ? 'empresa' : '',
                isPathActive('/detalle-producto-persona') ? 'persona' : '',
                isPathActive('/detalle-producto-empresa') ? 'empresa' : '',
                isPathActive('/contacto') ? 'contacto' : ''
              ]}
            >
              <Menu.Item key="home">
                <Link to="/home">
                  <span style={{ ...getActiveStyle('/home'), fontWeight: 700 }}>Inicio</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="productos" style={{ ...getActiveStyle('productos'), fontWeight: 700 }}>
                Productos
              </Menu.Item>

              <Menu.Item key="persona" className="submenu-item">
                <Link to='/producto-persona'>
                  <span style={{
                    ...getActiveStyle(['/producto-persona', '/detalle-producto-persona', true]),
                    display: 'block',
                    fontSize: 14
                  }}>
                    Persona
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="empresa" className="submenu-item line-menu">
                <Link to='/producto-empresa'>
                  <span style={{
                    ...getActiveStyle('/producto-empresa', '/detalle-producto-persona', true),
                    display: 'block',
                    position: 'relative',
                    top: -13,
                    fontSize: 14
                  }}>
                    Empresa
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="contacto" style={{ top: -10 }}>
                <Link to="/contacto">
                  <span style={{ ...getActiveStyle('/contacto'), fontWeight: 700 }}>Contacto</span>
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>

        <Row gutter={[24, 24]} align="middle">
          {/* Texto de Copyright  desktop*/}
          <Col xs={0} sm={0} md={18} style={{ paddingTop: 30 }}>
            <Flex justify="flex-start">
              <Text className='term-text'>
                © Copyright - 2024. Todos los derechos reservados.{' '}
                <Button type="link" className="term" onClick={showModal}>
                  Ver Términos y Condiciones
                </Button>
              </Text>
            </Flex>
          </Col>

          <Col xs={24} sm={24} md={0}>
            <Flex justify="flex-start">
              <Text className='term-text'>
                © Copyright - 2024. Todos los derechos reservados.{' '}

              </Text>

            </Flex>
            <Button type="link" className="term-link" onClick={showModal}>
              Ver Términos y Condiciones
            </Button>
          </Col>

          {/* Iconos de redes sociales */}
          <Col xs={0} sm={0} md={6}>
            <Flex justify="flex-end">
              <Space>
                <Button
                  size={34}
                  shape="circle"
                  href="https://www.facebook.com/bolsadesantiago/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='circle-redes'
                >
                  <svg
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    xmlns="http://www.w3.org/2000/svg"
                    class="hover-svg"
                  >
                    <path
                      d="M5.41862 2.23125C6.00243 2.23125 6.62591 2.40625 6.62591 2.40625L7 0.262501C7 0.262501 6.20648 0 4.31336 0C3.15142 0 2.47692 0.426563 1.98381 1.05547C1.51903 1.65156 1.50202 2.60859 1.50202 3.22656V4.63203H0V6.72656H1.50202V14H4.31336V6.72656H6.54089L6.70526 4.63203H4.31336V2.99688C4.31336 2.43359 4.83482 2.23125 5.41862 2.23125Z"
                    />
                  </svg>
                </Button>
                <Button
                  size={34}
                  shape="circle"
                  href="https://www.linkedin.com/company/35683183/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='circle-redes'
                >
                  <svg class="hover-svg" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.50873 0C0.596461 0 0 0.648198 0 1.50016C0 2.33332 0.578693 3 1.47373 3H1.49104C2.42116 3 3 2.33332 3 1.50016C2.98261 0.648198 2.42116 0 1.50873 0Z" />
                    <path d="M0 4H3V14H0V4Z" />
                    <path d="M11.2285 4C9.46119 4 8.27612 5.64629 8.27612 5.64629V4.22932H5V14H8.27594V8.54367C8.27594 8.25157 8.2973 7.95992 8.38386 7.75104C8.62068 7.16775 9.15963 6.56348 10.0647 6.56348C11.2501 6.56348 11.7242 7.45949 11.7242 8.77299V14H15V8.39767C15 5.39654 13.3837 4 11.2285 4Z" />
                  </svg>
                </Button>
                <Button
                  size={34}
                  shape="circle"
                  href="https://www.instagram.com/labolsadesantiago/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='circle-redes'
                >
                  <svg class="hover-svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1589_8173)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5 6.51472 11.4853 4.5 9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 11.4853 6.51472 13.5 9 13.5ZM9 12C10.6568 12 12 10.6568 12 9C12 7.34314 10.6568 6 9 6C7.34314 6 6 7.34314 6 9C6 10.6568 7.34314 12 9 12Z" />
                      <path d="M13.5 3.75C13.0858 3.75 12.75 4.08579 12.75 4.5C12.75 4.91421 13.0858 5.25 13.5 5.25C13.9142 5.25 14.25 4.91421 14.25 4.5C14.25 4.08579 13.9142 3.75 13.5 3.75Z" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.24047 3.20705C0.75 4.16965 0.75 5.42977 0.75 7.95V10.05C0.75 12.5702 0.75 13.8304 1.24047 14.7929C1.6719 15.6397 2.36031 16.3281 3.20705 16.7595C4.16965 17.25 5.42977 17.25 7.95 17.25H10.05C12.5702 17.25 13.8304 17.25 14.7929 16.7595C15.6397 16.3281 16.3281 15.6397 16.7595 14.7929C17.25 13.8304 17.25 12.5702 17.25 10.05V7.95C17.25 5.42977 17.25 4.16965 16.7595 3.20705C16.3281 2.36031 15.6397 1.6719 14.7929 1.24047C13.8304 0.75 12.5702 0.75 10.05 0.75H7.95C5.42977 0.75 4.16965 0.75 3.20705 1.24047C2.36031 1.6719 1.6719 2.36031 1.24047 3.20705ZM10.05 2.25H7.95C6.66513 2.25 5.79169 2.25117 5.11656 2.30632C4.45893 2.36005 4.12263 2.45744 3.88803 2.57698C3.32354 2.8646 2.8646 3.32354 2.57698 3.88803C2.45744 4.12263 2.36005 4.45893 2.30632 5.11656C2.25117 5.79169 2.25 6.66513 2.25 7.95V10.05C2.25 11.3349 2.25117 12.2083 2.30632 12.8834C2.36005 13.5411 2.45744 13.8774 2.57698 14.112C2.8646 14.6764 3.32354 15.1354 3.88803 15.423C4.12263 15.5425 4.45893 15.64 5.11656 15.6937C5.79169 15.7488 6.66513 15.75 7.95 15.75H10.05C11.3349 15.75 12.2083 15.7488 12.8834 15.6937C13.5411 15.64 13.8774 15.5425 14.112 15.423C14.6764 15.1354 15.1354 14.6764 15.423 14.112C15.5425 13.8774 15.64 13.5411 15.6937 12.8834C15.7488 12.2083 15.75 11.3349 15.75 10.05V7.95C15.75 6.66513 15.7488 5.79169 15.6937 5.11656C15.64 4.45893 15.5425 4.12263 15.423 3.88803C15.1354 3.32354 14.6764 2.8646 14.112 2.57698C13.8774 2.45744 13.5411 2.36005 12.8834 2.30632C12.2083 2.25117 11.3349 2.25 10.05 2.25Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1589_8173">
                        <rect width="18" height="18" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>
              </Space>
            </Flex>
          </Col>

          {/* Iconos para vista móvil */}
          <Col xs={24} sm={24} md={0}>
            <Flex justify="flex-start">
              <Space>
                <Button
                  size={34}
                  shape="circle"
                  href="https://www.facebook.com/bolsadesantiago/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='circle-redes'
                >
                  <svg
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    xmlns="http://www.w3.org/2000/svg"
                    class="hover-svg"
                  >
                    <path
                      d="M5.41862 2.23125C6.00243 2.23125 6.62591 2.40625 6.62591 2.40625L7 0.262501C7 0.262501 6.20648 0 4.31336 0C3.15142 0 2.47692 0.426563 1.98381 1.05547C1.51903 1.65156 1.50202 2.60859 1.50202 3.22656V4.63203H0V6.72656H1.50202V14H4.31336V6.72656H6.54089L6.70526 4.63203H4.31336V2.99688C4.31336 2.43359 4.83482 2.23125 5.41862 2.23125Z"
                    />
                  </svg>
                </Button>
                <Button
                  size={34}
                  shape="circle"
                  href="https://www.linkedin.com/company/35683183/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='circle-redes'
                >
                  <svg class="hover-svg" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.50873 0C0.596461 0 0 0.648198 0 1.50016C0 2.33332 0.578693 3 1.47373 3H1.49104C2.42116 3 3 2.33332 3 1.50016C2.98261 0.648198 2.42116 0 1.50873 0Z" />
                    <path d="M0 4H3V14H0V4Z" />
                    <path d="M11.2285 4C9.46119 4 8.27612 5.64629 8.27612 5.64629V4.22932H5V14H8.27594V8.54367C8.27594 8.25157 8.2973 7.95992 8.38386 7.75104C8.62068 7.16775 9.15963 6.56348 10.0647 6.56348C11.2501 6.56348 11.7242 7.45949 11.7242 8.77299V14H15V8.39767C15 5.39654 13.3837 4 11.2285 4Z" />
                  </svg>
                </Button>
                <Button
                  size={34}
                  shape="circle"
                  href="https://www.instagram.com/labolsadesantiago/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='circle-redes'
                >
                  <svg class="hover-svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1589_8173)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5 6.51472 11.4853 4.5 9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 11.4853 6.51472 13.5 9 13.5ZM9 12C10.6568 12 12 10.6568 12 9C12 7.34314 10.6568 6 9 6C7.34314 6 6 7.34314 6 9C6 10.6568 7.34314 12 9 12Z" />
                      <path d="M13.5 3.75C13.0858 3.75 12.75 4.08579 12.75 4.5C12.75 4.91421 13.0858 5.25 13.5 5.25C13.9142 5.25 14.25 4.91421 14.25 4.5C14.25 4.08579 13.9142 3.75 13.5 3.75Z" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.24047 3.20705C0.75 4.16965 0.75 5.42977 0.75 7.95V10.05C0.75 12.5702 0.75 13.8304 1.24047 14.7929C1.6719 15.6397 2.36031 16.3281 3.20705 16.7595C4.16965 17.25 5.42977 17.25 7.95 17.25H10.05C12.5702 17.25 13.8304 17.25 14.7929 16.7595C15.6397 16.3281 16.3281 15.6397 16.7595 14.7929C17.25 13.8304 17.25 12.5702 17.25 10.05V7.95C17.25 5.42977 17.25 4.16965 16.7595 3.20705C16.3281 2.36031 15.6397 1.6719 14.7929 1.24047C13.8304 0.75 12.5702 0.75 10.05 0.75H7.95C5.42977 0.75 4.16965 0.75 3.20705 1.24047C2.36031 1.6719 1.6719 2.36031 1.24047 3.20705ZM10.05 2.25H7.95C6.66513 2.25 5.79169 2.25117 5.11656 2.30632C4.45893 2.36005 4.12263 2.45744 3.88803 2.57698C3.32354 2.8646 2.8646 3.32354 2.57698 3.88803C2.45744 4.12263 2.36005 4.45893 2.30632 5.11656C2.25117 5.79169 2.25 6.66513 2.25 7.95V10.05C2.25 11.3349 2.25117 12.2083 2.30632 12.8834C2.36005 13.5411 2.45744 13.8774 2.57698 14.112C2.8646 14.6764 3.32354 15.1354 3.88803 15.423C4.12263 15.5425 4.45893 15.64 5.11656 15.6937C5.79169 15.7488 6.66513 15.75 7.95 15.75H10.05C11.3349 15.75 12.2083 15.7488 12.8834 15.6937C13.5411 15.64 13.8774 15.5425 14.112 15.423C14.6764 15.1354 15.1354 14.6764 15.423 14.112C15.5425 13.8774 15.64 13.5411 15.6937 12.8834C15.7488 12.2083 15.75 11.3349 15.75 10.05V7.95C15.75 6.66513 15.7488 5.79169 15.6937 5.11656C15.64 4.45893 15.5425 4.12263 15.423 3.88803C15.1354 3.32354 14.6764 2.8646 14.112 2.57698C13.8774 2.45744 13.5411 2.36005 12.8834 2.30632C12.2083 2.25117 11.3349 2.25 10.05 2.25Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1589_8173">
                        <rect width="18" height="18" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>
              </Space>
            </Flex>
          </Col>
        </Row>

      </div>
      <ModalTerminosCondiciones isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </div>
  );
};

export default FooterProd;
