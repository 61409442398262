import React from 'react'
import { Card, Row, Col, Space, Button, Flex, Typography } from 'antd'
import { getSubtituloPlan } from '../../services/helpers/stringHelper'
import { SafetyCertificateOutlined } from '@ant-design/icons';
import { onRedirectToProducto } from '../../services/helpers/stringHelper';
import { separadorDeMiles } from '../../utils/Utils';
import LogoPlan from '../detalle-producto-persona/LogoPlan';
import { ShieldDone } from 'react-iconly';


const { Text } = Typography;
const ProductoApiEmpresa = ({ plan }) => {

    return (
        <Card className='card-detail-prod' bordered={false} style={{ background: '#3D3D3D' }}>
            <Row justify="space-between" gutter={0}>
                <Col span={24} style={{ marginBottom: 24 }}>
                    <Space>
                        <LogoPlan nombrePlan={plan?.nombrePlan}></LogoPlan>
                        <Row>
                            <Col span={24}>
                                {plan.nombrePlan === 'Básico' ? (
                                    <p style={{
                                        fontWeight: 'bold',
                                        fontSize: 24,
                                        color: '#FFFBFA',
                                        marginBottom: 0,
                                        fontFamily: 'Degular',
                                        lineHeight: '1.2',
                                    }}>Básico</p>
                                ) : (
                                    <p style={{
                                        fontWeight: 'bold',
                                        textAlign: 'justify',
                                        fontSize: 24,
                                        color: '#FFFBFA',
                                        marginBottom: 0,
                                        marginLeft: 8,
                                        fontFamily: 'Degular',
                                        lineHeight: '1.2',
                                    }}>{plan?.nombrePlan}</p>
                                )}
                            </Col>
                            <Col span={24}>
                                <p style={{
                                    fontSize: 12,
                                    color: '#FFFBFA',
                                    marginTop: 0,
                                    marginLeft: 8,
                                    fontFamily: 'Degular',
                                    lineHeight: '1.5',
                                }}>{getSubtituloPlan(plan?.nombrePlan)}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p style={{
                                    fontWeight: 'bold',
                                    fontSize: 28,
                                    color: '#FFFBFA',
                                    fontFamily: 'PT Serif',
                                    margin: 0,
                                    lineHeight: '1.2',
                                    minHeight: '2.3em',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                }}>
                                    UF {plan.precio} /mes
                                </p>
                            </Col>
                        </Row>
                    </Space>
                </Col>


                {/* version antigua por si se debe actualizar */}
                {/* <Col xs={0} sm={0} md={24} lg={13} xl={13} xxl={13}>
                    <Row>
                        <Col xs={0} sm={0} md={7} lg={6} xl={6} xxl={6}>
                            <LogoPlan nombrePlan={plan?.nombrePlan}></LogoPlan>
                        </Col>
                        <Col xs={0} sm={0} md={17} lg={18} xl={18} xxl={18}>
                            {plan.nombrePlan === 'Básico' ? <p style={{ fontWeight: 'bold', fontSize: 24, color: '#FFFBFA', color: '#FFFBFA', marginBottom: 0, fontFamily: 'Degular' }}>Básico</p> :
                                <p style={{ fontWeight: 'bold', textAlign: 'justify', marginBottom: 0, fontSize: 24, color: '#FFFBFA', marginLeft: 8, fontFamily: 'Degular' }}>{plan?.nombrePlan}</p>}
                            <p style={{ fontSize: 12, color: '#FFFBFA', marginTop: 0, marginLeft: 8, fontFamily: 'Degular' }}>{getSubtituloPlan(plan?.nombrePlan)}</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={0} sm={0} md={24} lg={11} xl={11} xxl={11} style={{ marginRight: 0, paddingRight: 0 }}>
                    <p style={{ fontWeight: 'bold', fontSize: 28, color: '#FFFBFA', fontFamily: 'PT Serif' }}>UF {plan.precio} /mes</p>
                </Col> */}
            </Row>
            <div style={{ color: '#FFFBFA' }}>
                <p style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: 24, fontFamily: 'Degular', marginTop: 16, marginBottom: 12 }}>Este plan incluye</p>

                <Row gutter={8} style={{ marginTop: 16 }}>
                    <Col span={24}>
                        <Space size={8} style={{ alignItems: 'normal' }}>
                            <ShieldDone size={24} style={{ color: '#FF411C', }} />
                            <Text style={{ fontFamily: 'Degular', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}><span className='estilos-mercado'>N° Requests / {plan.nombrePlan === 'Básico' ? 'día' : 'mes'}: </span><span className='estilos-textos' style={{ paddingLeft: 8 }}>{separadorDeMiles(plan.cantidadRequest)}</span></Text>
                        </Space>
                    </Col>
                </Row>
                {plan.mercados === 'C&L' ?
                    (
                        <Row gutter={8} style={{ marginTop: 16 }}>
                            <Col span={24}>
                                <Space size={8} style={{ alignItems: 'normal' }}>
                                    <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                    <Text style={{ fontFamily: 'Degular', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}><span className='estilos-mercado'>Mercado: </span><span className='estilos-textos' style={{ paddingLeft: 8 }}>Compensación y Liquidación</span></Text>
                                </Space>
                            </Col>
                        </Row>
                    ) : (
                        <Row gutter={8} style={{ marginTop: 16 }}>
                            <Col span={24}>
                                <Space size={8} style={{ alignItems: 'normal' }}>
                                    <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                    <Text style={{ fontFamily: 'Degular', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}><span className='estilos-mercado'>Mercado: </span><span className='estilos-textos' style={{ paddingLeft: 8 }}>{plan.mercados?.replace('Mercado:', '')}</span></Text>
                                </Space>
                            </Col>
                        </Row>
                    )}

                {plan.nombrePlan === 'Básico' ?
                    (<Row gutter={8} style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Space size={8} style={{ alignItems: 'normal' }}>
                                <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                <Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>Data End of day</Text>
                            </Space>
                        </Col>
                    </Row>
                    ) :
                    (<Row gutter={8} style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Space size={8} style={{ alignItems: 'normal' }}>
                                <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                <Text style={{ fontFamily: 'Degular', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}><span className='estilos-mercado'>Periodo de tiempo: </span><span className='estilos-textos' style={{ paddingLeft: 8 }}>15 min Delayed</span></Text>
                            </Space>
                        </Col>
                    </Row>
                    )}

                {plan.nombrePlan === 'Básico' ?
                    (<Row gutter={8} style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Space size={8} style={{ alignItems: 'normal' }}>
                                <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                <Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16 }}>10 instrumentos aleatorios</Text>
                            </Space>
                        </Col>
                    </Row>
                    ) :
                    (<Row gutter={8} style={{ marginTop: 16 }}>
                         <Col span={24}>
                            <Space size={8} style={{ alignItems: 'normal' }}>
                                <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                <Text style={{ fontFamily: 'Degular', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}><span className='estilos-mercado'>Selección de instrumentos: </span><span className='estilos-textos' style={{ paddingLeft: 8 }}>Si</span></Text>
                            </Space>
                        </Col>
                    </Row>
                    )}
                <Row gutter={8} style={{ marginTop: 16 }}>
                    <Col span={24}>
                        <Space size={8} style={{ alignItems: 'normal' }}>
                            <ShieldDone size={24} style={{ color: '#FF411C', }} />
                            <Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16 }}>Info. Historica: </Text><Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>{plan.infoHistorica} {plan.infoHistorica > 1 ? 'años' : 'año'}</Text>
                        </Space>
                    </Col>
                </Row>
            </div>
            <Flex justifyContent='center' style={{ marginTop: 24 }}>

                {plan?.precio === 0 && (
                    <Button
                        onClick={() => onRedirectToProducto(plan.idPlanProducto, true)}
                        className='btn-onboarding-empresa'
                        style={{ width: '100%' }}
                    >
                        <strong>Prueba gratis</strong>
                    </Button>
                )}
                {plan?.precio > 0 && (
                    <Button
                        onClick={() => onRedirectToProducto(plan.idPlanProducto, false)}
                        className='btn-onboarding-empresa'
                        style={{ width: '100%' }}
                    >
                        <strong>Contratar plan</strong>
                    </Button>
                )}

            </Flex>
        </Card >
    )
}

export default ProductoApiEmpresa