import React, { useRef } from 'react'
import BannerProductoEmpresa from './BannerProductoEmpresa'
import CaracteristicasProductoEmpresa from './CaracteristicasProductoEmpresa'
import ContratarProductosEmpresa from './ContratarProductosEmpresa'
import ProductosEmpresa from './ProductosEmpresa'
import PreguntasFrecuentesProductoEmpresa from './PreguntasFrecuentesProductoEmpresa'

const Index = () => {
  const ref = useRef(null);
    const irAPreguntasFrecuentes = () => {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
  return (
    <>
      <BannerProductoEmpresa />
      <div style={{ backgroundColor: '#FFFBFA', overflowX: 'hidden' }}>
        <CaracteristicasProductoEmpresa />
        <ContratarProductosEmpresa irAPreguntasFrecuentes={() => irAPreguntasFrecuentes()}/>
        <ProductosEmpresa />
        <PreguntasFrecuentesProductoEmpresa refPreguntas={ref} />
      </div>
    </>
  )
}

export default Index