import React from 'react'
import { Card, Row, Col, Space, Button, Image, Typography } from 'antd'
import { AimOutlined, RocketOutlined, CheckOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';

import { ShieldDone } from 'react-iconly'

import { separadorDeMilesComas } from '../../utils/Utils';

const { Text } = Typography;

const ProductoDashboardEmpresa = ({ plan, index }) => {
    return (
        <Card className='card-detail-prod' bordered={false} >
            <Row justify="space-between" gutter={0}>
                <Col span={24} style={{ marginBottom: 24 }}>
                    <Space>
                        {/* LOGO */}
                        <div style={{ marginTop: 10 }}>
                            {plan?.nombrePlan === 'Prueba gratuita' ?
                                <RocketOutlined className="colorTipoPlan" style={{ fontSize: '38px', color: '#fff' }} /> :
                                plan?.nombrePlan === 'Individual' ?
                                    <AimOutlined className="colorTipoPlan" style={{ fontSize: '38px', color: '#fff' }} /> :
                                    plan?.nombrePlan.includes('Paquete') ?
                                        <RocketOutlined className="colorTipoPlan" style={{ fontSize: '38px', color: '#fff' }} /> : null}
                        </div>
                        <Row>
                            <Col span={24}>
                                {plan.nombrePlan === 'Básico' ? <p style={{ fontWeight: 'bold', fontSize: 24, color: '#FFFBFA', color: '#FFFBFA', marginBottom: 0, fontFamily: 'Degular' }}>Básico</p> :
                                    <p style={{ fontWeight: 'bold', textAlign: 'justify', marginBottom: 0, fontSize: 24, color: '#FFFBFA', marginLeft: 8, fontFamily: 'Degular', lineHeight: 1 }}>{plan?.nombrePlan}</p>}
                            </Col>
                            <Col span={24}>
                                <p style={{ fontSize: 12, color: '#FFFBFA', marginTop: 0, marginLeft: 8, fontFamily: 'Degular' }}>{plan?.cantidadDashboard} Dashboards</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p style={{
                                    fontWeight: 'bold',
                                    fontSize: 28,
                                    color: '#FFFBFA',
                                    fontFamily: 'PT Serif',
                                    margin: 0,
                                    lineHeight: '1.2',
                                    minHeight: '2.3em',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                }}>
                                    UF {plan.precio} /mes
                                </p>
                            </Col>
                        </Row>
                    </Space>
                </Col>
            </Row>
            <div style={{ color: '#FFFBFA' }}>
                <p style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: 24, fontFamily: 'Degular', marginTop: 16, marginBottom: 12 }}>Este plan incluye</p>

                <div className='containerDescripcion'>
                    {
                        plan?.descripcionPlanDashboard?.split('<p>')?.map(x => {
                            if (x !== '') {
                                return <Row gutter={8} style={{ marginTop: 16 }}>
                                    <Col span={24}>
                                        <Space size={8} style={{ alignItems: 'normal' }}>
                                            <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                            <Text style={{ fontFamily: 'Degular', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}>{x.replace('</p>', '')}</Text>
                                        </Space>
                                    </Col>
                                </Row>
                            }
                        })
                    }
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>

                {plan?.precio === 0 ? (<Button disabled className='btn-onboarding-disabled' style={{ width: '100%' }}><strong>Próximamente</strong></Button>) : null}

                {plan?.precio > 0 ? <Button disabled className='btn-onboarding-disabled' style={{ width: '100%' }}><strong>Próximamente</strong></Button> : null}

            </div>
        </Card>
    )
}

export default ProductoDashboardEmpresa