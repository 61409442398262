import { CloseOutlined } from '@ant-design/icons';
import { Image, Button, Col, Drawer, Flex, Layout, Menu, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'react-iconly'
import logo from '../../assets/logos/Brand.svg';
import ModalTerminosCondiciones from '../modals/ModalTerminosCondiciones';
import menu from '../../assets/images/Menu.svg'
import closeIcon from '../../assets/images/closeIcon.svg'

const { Header } = Layout;
const { Text } = Typography;
const HeaderHome = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isModalOpenTerminosYCondiciones, setIsModalOpenTerminosYCondiciones] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [current, setCurrent] = useState('');

  const [openKeys, setOpenKeys] = useState([]);


  const handleClick = () => {
    navigate('/seleccion-pais');
  };

  const showModalTerminosYCondiciones = () => {
    setIsModalOpenTerminosYCondiciones(true)
  }
  const handleOkTerminosYCondiciones = () => {
    setIsModalOpenTerminosYCondiciones(false);
  };

  const handleCancelTerminosYCondiciones = () => {
    setIsModalOpenTerminosYCondiciones(false);
  };


  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };


  useEffect(() => {
    const activeItem = items.find(item => {
      if (item.children) {
        return item.children.some(child =>
          child.pathnames?.includes(location.pathname)
        );
      }
      return item.pathnames?.includes(location.pathname);
    });
    if (activeItem) {
      setCurrent(activeItem.key);
    }
  }, [location]);

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const getClaseSelectedTipoProductoMobile = (item) => {
    const currentPath = location.pathname

    if (currentPath === '/producto-empresa' && item === 'empresa') {
      return 'submenu-persona-empresa-active'
    }
    else if (currentPath === '/producto-persona' && item === 'persona') {
      return 'submenu-persona-active'
    }
    else {
      return ''
    }
  }

  const getClaseSelectedTipoProducto = () => {
    const currentPath = location.pathname

    if (currentPath === '/producto-empresa') {
      return 'submenu-popup menu-web-selected-empresa'
    }
    else if (currentPath === '/producto-persona') {
      return 'submenu-popup menu-web-selected-persona'
    }
    else {
      return 'submenu-popup'
    }
  }

  const items = [
    {
      key: '1',
      label: <span tabIndex='0'> <Link to='/home'>Inicio</Link></span>,
      pathnames: ['/home', '/'],
      className: 'menu-web',
    },

    {
      key: '2',

      label: (
        <>
          <span className='spanHeader' tabIndex='1'>Productos</span>
          {openKeys.includes('2') ? (
            <ChevronUp style={{ marginLeft: 8 }} />
          ) : (
            <ChevronDown style={{ marginLeft: 8 }} />
          )}
        </>
      ),
      open: true,
      popupOffset: [-10, 0],
      popupClassName: getClaseSelectedTipoProducto(),
      children: [
        {
          key: '2.1',
          label: <Link to='/producto-persona'>Personas</Link>,
          pathnames: ['/producto-persona', '/detalle-producto-persona']
        },
        {
          key: '2.2',
          label: <Link to='/producto-empresa'>Empresas</Link>,
          pathnames: ['/producto-empresa', '/detalle-producto-empresa']
        }
      ]
    },
    {
      key: '3',
      label: <span className='spanHeader' tabIndex='2'><Link to='/contacto'>Contacto</Link></span>,
      pathnames: ['/contacto'],
      className: 'menu-web',
    },
    {
      label: (
        <Button className='btn-onboarding-g' type="primary" onClick={handleClick}>
          <strong>Registrarme</strong>
        </Button>
      )
    }
  ];

  const itemsMobile = [
    {
      key: '1',
      label: <Link to='/home' style={{ fontSize: 16 }}>Inicio</Link>,
      id: 1,
      className: 'menu-mobile',
      pathnames: ['/home', '/'],
    },
    {
      key: '2',
      // label: 'Productos',
      label: (
        <>
          <span>Productos</span>
          {openKeys.includes('2') ? (
            <ChevronUp style={{ marginLeft: 8 }} />
          ) : (
            <ChevronDown style={{ marginLeft: 8 }} />
          )}
        </>
      ),
      id: 2,
      className: `menu-mobile-subItem ${location.pathname === '/producto-empresa' || location.pathname === '/producto-persona' ? 'active' : ''}`,
      children: [
        {
          key: '2.1',
          label: <Link to='/producto-persona' style={{ fontSize: 16 }}>Personas</Link>,
          className: `submenu-persona ${getClaseSelectedTipoProductoMobile('persona')}`,
          id: 2,
          pathnames: ['/producto-persona']
        },
        {
          key: '2.2',
          label: <Link to='/producto-empresa' style={{ fontSize: 16 }}>Empresas</Link>,
          className: `submenu-persona-empresa ${getClaseSelectedTipoProductoMobile('empresa')}`,
          id: 2.2,
          pathnames: ['/producto-empresa']
        }
      ]
    },
    {
      key: '3',
      label: <Link to='/contacto' style={{ fontSize: 16 }}>Contacto</Link>,
      id: 3,
      className: 'menu-mobile-contacto',
      pathnames: ['/contacto']
    },
    {
      key: '4',
      label: (
        <Button className='btn-onboarding-g' type="primary" onClick={handleClick}>
          <strong>Registrarme</strong>
        </Button>
      ),
      className: 'menu-mobile',
      id: 4,
    }
  ]


  const obtenerKeysSeleccionadas = (pathname, items) => {
    const selectedKeys = [];

    const obtenerItems = (items) => {
      items.forEach((item) => {
        if (item.pathnames && item.pathnames.includes(pathname)) {
          selectedKeys.push(item.key);
        }
        if (item.children) {
          obtenerItems(item.children);
        }
      });
    };

    obtenerItems(items);
    return selectedKeys;
  }


  const onOpenChange = (openKeys) => {
    setOpenKeys(openKeys)
  }

  const getItemsMenuDesktop = () => {
    if (location.pathname === '/seleccion-pais') {
      return items?.filter(x => x.key)
    }
    else {
      return items
    }
  }

  const getItemsMenuMobile = () => {
    if (location.pathname === '/seleccion-pais') {
      return itemsMobile?.filter(x => x.id !== 4)
    }
    else {
      return itemsMobile
    }
  }


  return (
    <Header className='headers'>
      <Row justify="end" align="middle" style={{ width: '100%' }}>
        {/* menu desktop */}
        <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
          <Row gutter={0}>
            <Col span={12}>
              <Link to="/home">
                <img alt='nuam' src={logo} style={{ width: 142, height: 32, paddingTop: 8 }}></img>
              </Link>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Menu
                theme="dark"
                mode="horizontal"
                className="menu"
                items={getItemsMenuDesktop(items)}
                onOpenChange={onOpenChange}
                onClick={onClick}
                selectedKeys={[current]}
                style={{
                  flex: 1,
                  justifyContent: 'flex-end',
                  border: 'none'
                }}
              >
              </Menu>
            </Col>
          </Row>
        </Col>

        {/* menumobile */}
        <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} >
          <Row gutter={0}>
            <Col span={20}>
              <Link to="/home">
                <img alt='nuam' src={logo} style={{ width: 142, height: 32, paddingTop: 30 }}></img>
              </Link>
            </Col>
            <Col span={4}>
              <div className="menu-icon-circle" onClick={showDrawer} style={{ color: 'white', marginTop: 30, textAlign: 'right' }}>
                <img src={menu} alt='menu' />
              </div>
            </Col>
          </Row>
          <Drawer
            title={
              <>
                <Flex align='center' justify='space-between' >
                  <img alt='nuam' src={logo} style={{ width: 142, height: 32 }}></img>
                  {drawerVisible && (
                    <img alt='nuam' src={closeIcon} style={{ width: 32, height: 32 }} onClick={onCloseDrawer}></img>
                  )}
                </Flex>
              </>
            }
            placement="right"
            closable={false}
            onClose={onCloseDrawer}
            open={drawerVisible}
            width='max-content'
            className='menudrawer'
            style={{ height: '70%', top: '15%' }}
          >
            <div className="drawer-content">
              <Menu
                className='menuMobile'
                theme="dark"
                onOpenChange={onOpenChange}
                defaultOpenKeys={['sub1']}
                mode="inline"
                onClick={onClick}
                selectedKeys={obtenerKeysSeleccionadas(location.pathname, itemsMobile)}
                items={getItemsMenuMobile(itemsMobile)}
              >
                {itemsMobile.map((item, index) => (
                  <Menu.Item
                    key={index}
                    onClick={onCloseDrawer}
                    className={`menu-item ${item.pathnames && item.pathnames.indexOf(location.pathname) !== -1 && item.pathnames.indexOf(location.pathname) !== 3 ? 'ant-menu-item-selected' : ''}`}
                  >
                  </Menu.Item>
                ))}
              </Menu>
              <div style={{ padding: '104px 20px 0px 20px' }}>
                <Row gutter={0} >
                  <Col span={24}>
                    <Text className="copyright-mobile">
                      © Copyright - 2024. Todos los derechos reservados.
                    </Text>
                  </Col>
                </Row>
                <Row gutter={0}>
                  <Col span={24}>
                    <Button
                      type="link"
                      className='btn-terminos-mobile'
                      onClick={showModalTerminosYCondiciones}
                    >
                      Ver Términos y Condiciones
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Drawer>
        </Col>
      </Row>
      <ModalTerminosCondiciones isModalOpen={isModalOpenTerminosYCondiciones} handleOk={handleOkTerminosYCondiciones} handleCancel={handleCancelTerminosYCondiciones} />
    </Header>
  );
}

export default HeaderHome