import React from 'react'
import { Card, Row, Col, Space, Button, Typography, Flex } from 'antd'
import { CheckOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { onRedirectToProducto } from '../../services/helpers/stringHelper';
import LogoPlan from '../detalle-producto-persona/LogoPlan';
import { ShieldDone } from 'react-iconly';


const { Text } = Typography
const ProductoInnovationLabEmpresa = ({ plan }) => {
    return (
        <>
            <Card
                className='card-detail-prod'
                bordered={false}
                style={{
                    background: '#3D3D3D',
                    //display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    display: 'block',
                }}
            >
                <Row justify="space-between" gutter={0}>
                    <Col span={24} style={{ marginBottom: 24 }}>
                        <Space>
                            {/* LOGO */}
                            <div style={{ marginTop: 10 }}>
                                <LogoPlan nombrePlan={plan?.nombrePlan}></LogoPlan>
                            </div>
                            <Row>
                                <Col span={24}>
                                    {plan.nombrePlan === 'Básico' ? <p style={{ fontWeight: 'bold', fontSize: 24, color: '#FFFBFA', color: '#FFFBFA', marginBottom: 0, fontFamily: 'Degular' }}>Básico</p> :
                                        <p style={{ fontWeight: 'bold', textAlign: 'justify', marginBottom: 0, fontSize: 24, color: '#FFFBFA', marginLeft: 8, fontFamily: 'Degular', lineHeight: 1 }}>{plan?.nombrePlan}</p>}
                                </Col>
                                <Col span={24}>
                                    <p style={{ fontSize: 12, color: '#FFFBFA', marginTop: 0, marginLeft: 8, fontFamily: 'Degular' }}>Innovation lab</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p style={{
                                        fontWeight: 'bold',
                                        fontSize: 28,
                                        color: '#FFFBFA',
                                        fontFamily: 'PT Serif',
                                        margin: 0,
                                        lineHeight: '1.2',
                                        minHeight: '2.3em',
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    }}>
                                        UF {plan.precio} /mes
                                    </p>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
                <p
                    style={{
                        textAlign: 'justify',
                        fontWeight: 'bold',
                        fontSize: 24,
                        fontFamily: 'Degular',
                        marginBottom: 12,
                        color: '#FFFBFA',
                    }}
                >
                    Este plan incluye
                </p>
                <div className='containerDescripcionInnoLab'>
                    <Row gutter={8} style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Space size={8} style={{ alignItems: 'normal' }}>
                                <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                <Text style={{ fontFamily: 'Degular', minHeight: '2.3em', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}><span className='estilos-mercado'>Tipo plan: </span><span className='estilos-textos' style={{ paddingLeft: 8 }}>{plan.descripcionPlanDashboard}</span></Text>
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={8} style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Space size={8} style={{ alignItems: 'normal' }}>
                                <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                <Text style={{ fontFamily: 'Degular', minHeight: '2.3em', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}><span className='estilos-mercado'>Usuarios: </span><span className='estilos-textos' style={{ paddingLeft: 8 }}>{plan.usuariosSugeridosInnovationLab}</span></Text>
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={8} style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Space size={8} style={{ alignItems: 'normal' }}>
                                <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                <Text style={{ fontFamily: 'Degular', minHeight: '2.3em', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}><span className='estilos-mercado'>Memoria Ram: </span><span className='estilos-textos' style={{ paddingLeft: 8 }}>{plan?.memoriaRam} GB</span></Text>
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={8} style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Space size={8} style={{ alignItems: 'normal' }}>
                                <ShieldDone size={24} style={{ color: '#FF411C', }} />
                                <Text style={{ fontFamily: 'Degular', minHeight: '2.3em', color: '#FFFBFA', fontSize: 16, marginTop: 16, marginRight: 8 }}><span className='estilos-mercado'>vCores: </span><span className='estilos-textos' style={{ paddingLeft: 8 }}>{plan?.cantidadNucleos} núcleos</span></Text>
                            </Space>
                        </Col>
                    </Row>
                </div>
                <div style={{ width: '100%', paddingTop: 20 }}>
                    {plan.nombrePlan === 'Workspace S' ? (
                        <Space direction='vertical' style={{ width: '100%' }}>
                            {plan?.nombrePlan === 'Básico' ? (
                                <Button
                                    onClick={() => onRedirectToProducto(plan.idPlanProducto, true)}
                                    className='btn-onboarding-empresa'
                                    style={{ width: '100%' }}
                                >
                                    Prueba gratis
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => onRedirectToProducto(plan.idPlanProducto, false)}
                                    className='btn-onboarding-empresa'
                                    style={{ width: '100%' }}
                                >
                                    Solicitar workspace
                                </Button>
                            )}
                        </Space>
                    ) : (
                        <Space direction='vertical' style={{ width: '100%' }}>
                            {plan?.nombrePlan === 'Básico' ? (
                                <Button
                                    onClick={() => onRedirectToProducto(plan.idPlanProducto, true)}
                                    className='btn-onboarding-empresa'
                                    style={{ width: '100%' }}
                                >
                                    Prueba gratis
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => onRedirectToProducto(plan.idPlanProducto, false)}
                                    className='btn-onboarding-empresa'
                                    style={{ width: '100%' }}
                                >
                                    Solicitar workspace
                                </Button>
                            )}
                        </Space>
                    )}
                </div>
            </Card>


        </>
    )
}

export default ProductoInnovationLabEmpresa