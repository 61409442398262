import React, { useEffect, useState } from 'react'
import { detalleProductosApi, getDocumento } from '../../services/producto';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { FileSearchOutlined, LeftOutlined, LoadingOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Space, Tag, Typography } from 'antd';
import parse from 'html-react-parser';
import { TipoDocumentoId } from '../../enum/enum';
import { Bag2, ChevronLeft, Paper } from 'react-iconly';
import imageDetails from '../../assets/images/detalle-prod.svg';
import imageDetailsMobile from '../../assets/images/detalle-prod-mobile.png'

const { Title, Text } = Typography;

const ContenidoDetalleProductoEmpresa = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const [detalleProductos, setDetalleProductos] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const idProducto = state ? state.productId : null;

        if (idProducto) {
          const informacionDetalleProducto = await detalleProductosApi(idProducto);
          setDetalleProductos(informacionDetalleProducto)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
    fetchData();

  }, [state]);

  const onDownloadDocumento = async (idProducto, idTipoDocumento) => {
    try {
      const docRes = await getDocumento(idProducto, idTipoDocumento)
      if (docRes) {
        const blobFile = new Blob(
          [docRes.buffer],
          { type: docRes.contentType }
        )
        saveAs(blobFile, docRes.fileName)
      } 
    } catch (error) {
      console.error("Error al descargar documento")
      console.error(error)
    }
    finally {
    }

  }

  return (
    <div>
      {loading ? (
        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20vh' }}>
          <LoadingOutlined />
        </Row>
      ) : (
        <>

          <Row gutter={0} className='row-details' >
            {detalleProductos && detalleProductos?.idiomas && (
              <Col xs={24} sm={18} md={14} xxl={17} style={{ textAlign: 'left', paddingRight: 8 }}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={4} sm={3} md={2}>
                    <Avatar className="avatar" icon={<Bag2 />} />
                  </Col>

                  <Col xs={20} sm={21} md={22} style={{ marginBottom: 20 }}>
                    <Text className='titulo-detalle' >
                      {detalleProductos?.idiomas[0]?.NombreProducto}
                    </Text>
                  </Col>
                  <Col xs={24} sm={24} md={0}>

                    <img src={imageDetailsMobile} alt='codigo' className='img-mobile' />
                  </Col>
                </Row>
                <Row gutter={0} className='margem-mobile'>
                  <Col xs={24} sm={24} md={13}>
                    <Text className='detalle-producto' style={{ color: '#F6FFFD' }}>
                      Descripción del producto
                    </Text>
                    <div className='margem-mobile'>
                      <Tag className="tag-details" style={{}}>Servicios de información</Tag>
                    </div>
                  </Col>
                </Row>
                <Row gutter={0}>
                  <Col xs={24} sm={22} md={24} className='marge-mobile'>
                    <Text className='text-details-prod' style={{ marginRight: 10 }}> {parse(detalleProductos?.idiomas[0]?.DescripcionDetalleProducto)}</Text>
                  </Col>
                </Row>
              </Col>
            )}

            <Col xs={0} sm={0} md={8} xxl={7} style={{ textAlign: 'right' }}>
              <img src={imageDetails} alt='empresa' />
            </Col>
          </Row>

          <Row className='row-details' >
            {detalleProductos?.idProducto === 11 ? (
              <>
                <Col xs={24} sm={24} md={0} lg={0} style={{ marginBottom: 24 }}>
                  <Button
                    className="btn-detalle-prod btn-volver"
                    icon={<ChevronLeft />}
                    onClick={() => navigate('/producto-empresa')}
                  >
                    Volver al catálogo
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} style={{ marginBottom: 24 }}>
                  <Button
                    disabled
                    className="btn-detalle-prod btn-contrato"
                    icon={<Paper />}
                    onClick={() => onDownloadDocumento(41, TipoDocumentoId.CONTRATO_PRODUCTO)}
                  >
                    Ver Contrato del producto
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} style={{ marginBottom: 24 }}>
                  <Button
                    className="btn-detalle-prod btn-folleto"
                    icon={<Paper />}
                    onClick={() => onDownloadDocumento(41, TipoDocumentoId.FOLLETO_PRODUCTO)}
                  >
                    Ver Folleto del producto
                  </Button>
                </Col>
              </>
            ) : (
              <>
                <Col xs={24} sm={24} md={0} lg={0} style={{ marginBottom: 24 }}>
                  <Button
                    icon={<ChevronLeft />}
                    onClick={() => navigate('/producto-empresa')}
                    className="btn-detalle-prod btn-volver"
                  >
                    Volver al catálogo
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} style={{ marginBottom: 24 }}>
                  <Button
                    icon={<Paper />}
                    className="btn-detalle-prod btn-folleto"
                    onClick={() => onDownloadDocumento(detalleProductos.idProducto, TipoDocumentoId.CONTRATO_PRODUCTO)}
                  >
                    Ver Contrato del producto
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} style={{ marginBottom: 24 }}>
                  <Button
                    icon={<Paper />}
                    className="btn-detalle-prod btn-folleto"
                    onClick={() => onDownloadDocumento(detalleProductos.idProducto, TipoDocumentoId.FOLLETO_PRODUCTO)}
                  >
                    Ver Folleto del producto
                  </Button>
                </Col>
              </>
            )}
          </Row>
          <Row justify="center" align="middle" style={{ marginTop: 20 }}>
            {detalleProductos?.idProducto === 11 ? (
              <Space size={10}>
                <Col xs={0} sm={0} md={24}>
                  <Button
                    className="btn-detalle-prod btn-volver"
                    icon={<ChevronLeft />}
                    onClick={() => navigate('/producto-empresa')}
                  >
                    Volver al catálogo
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={24}>
                  <Button
                    disabled
                    className="btn-detalle-prod btn-contrato"
                    icon={<Paper />}
                    onClick={() => onDownloadDocumento(detalleProductos.idProducto, TipoDocumentoId.CONTRATO_PRODUCTO)}
                  >
                    Ver Contrato del producto
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={24}>
                  <Button
                    className="btn-detalle-prod btn-folleto"
                    icon={<Paper />}
                    onClick={() => onDownloadDocumento(detalleProductos.idProducto, TipoDocumentoId.FOLLETO_PRODUCTO)}>
                    Ver Folleto del producto
                  </Button>
                </Col>
              </Space>
            ) : (
              <Space size={10}>
                <Col xs={0} sm={0} md={24}>
                  <Button
                    icon={<ChevronLeft />}
                    onClick={() => navigate('/producto-empresa')}
                    className="btn-detalle-prod btn-volver"
                  >
                    Volver al catálogo
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={24}>
                  <Button
                    icon={<Paper />}
                    className="btn-detalle-prod btn-folleto"
                    onClick={() => onDownloadDocumento(detalleProductos.idProducto, TipoDocumentoId.CONTRATO_PRODUCTO)}
                  >
                    Ver Contrato del producto
                  </Button>
                </Col >
                <Col xs={0} sm={0} md={24}>
                  <Button
                    icon={<Paper />}
                    className="btn-detalle-prod btn-folleto"
                    onClick={() => onDownloadDocumento(detalleProductos.idProducto, TipoDocumentoId.FOLLETO_PRODUCTO)}>Ver Folleto del producto</Button>
                </Col>
              </Space>
            )}
          </Row>

        </>
      )}
    </div >
  )
}

export default ContenidoDetalleProductoEmpresa