import { Col, Row, Typography } from 'antd'
import React from 'react'
import Formulario from './Formulario'
import PreguntasFrecuentes from './PreguntasFrecuentes'

const { Title, Text } = Typography;
const Contacto = () => {
    return (
        <>

            <Row className='row-contact'>
                <Row>
                    <Col xs={24} sm={24} md={12}>
                        {/* Título y textos */}
                        <Row gutter={12}>
                            <Col span={24} className='padd-1' style={{ paddingBottom: 12 }}>
                                <Text className='title-contact' style={{ color: '#F6FFFD', marginBottom: '16px' }}>
                                    Contacto
                                </Text>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} className='padd-2' style={{}}>
                                <Text className='subtitle-contact' style={{ color: '#F6FFFD', marginBottom: '12px' }}>
                                    Soporte Marketplace
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={23} className='padd-2' style={{}}>
                                <Text className='text-contact' style={{ color: '#F6FFFD', display: 'block' }}>
                                    ¿Tienes alguna duda o necesidad particular que podamos atender?
                                </Text>
                                <Text className='text-contact' style={{ color: '#F6FFFD', display: 'block' }}>
                                    Escríbenos y te contactaremos a la brevedad para resolver todas tus inquietudes.
                                </Text>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                                <Formulario />
                            </Col>

                        </Row>

                        <Row>
                            <Col span={24} style={{ paddingTop: 24 }}>
                                <PreguntasFrecuentes />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
                        <Formulario />
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default Contacto