import { Button, Card, Checkbox, Col, Divider, Flex, Form, Input, Row, Select, Space, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ModalTerminosCondiciones from '../modals/ModalTerminosCondiciones';
import { ChevronDown, ChevronUp } from 'react-iconly'


const { Title, Text }  = Typography;
const Formulario = () => {

    const [form] = Form.useForm()

    const [isDownloadDocumentacion, setIsDownloadDocumentacion] = useState(false)
    const [formIsSubmiting, setFormIsSubmiting] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [disabledSave, setDisabledSave] = useState(true);
    const [isTipoConsulta, setIsTipoConsulta] = useState(false);


    const validateEmail = (rule, value) => {
        if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject('Ingresa un correo electrónico válido');
    };
    
    const onFinish = async (values) => {
        try {
          setFormIsSubmiting(true)
          await 'generarSolicitudContacto(values)'
        }
        catch (error) {
          console.log(error)
        }
        finally {
          setOpenModal(true)
          form.resetFields()
          setFormIsSubmiting(false)
        }
    }

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
          setIsModalOpen(false);
    };
    
    const handleCancel = () => {
          setIsModalOpen(false);
    };
    
    const onDownloadTerminosYcondiciones = async () => {
        setIsDownloadDocumentacion(true)
        try {
          const docRes = await 'getTerminosCondicionesPortal()'
    
          if (docRes) {
            const blob = new Blob([docRes.buffer], { type: 'application/pdf' });
            const linkRedirect = URL.createObjectURL(blob);
    
            window.open(linkRedirect, "_blank");
          } 
        } catch (error) {
          console.error("Error al descargar documento")
          console.error(error)
        } finally {
          setIsDownloadDocumentacion(false)
        }
      }
    
      const onChange = (key) => {
    
    };

    const handleFormChange = (_, allValues) => {
      const allFieldsFilled = Object.values(allValues).every(value => value);
      setDisabledSave(!allFieldsFilled);
    };



  return (
    <>
        <Card className='formulario'>
            <Row>
                <Col span={24} style={{ paddingBottom: 8 }}>
                    <Text className='titulo-formulario' style={{ color: '#FF411C' }}>¡Hablemos!</Text>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Text className='subtitulo-formulario' style={{  }}>¿Tienes alguna duda o necesidad particular que podamos atender? </Text>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Text className='subtitulo-formulario'  style={{  }}>Escríbenos y te contactaremos a la brevedad para resolver todas tus inquietudes.</Text>
                </Col>
            </Row>
            <Divider className='divider-contact'/>
        <Row>
          <Form form={form} onFinish={onFinish} className="form formContacto" layout='vertical' style={{ width: '100%' }} onValuesChange={handleFormChange}>
            <Col span={24}>
              <Form.Item name='nombre' label='Nombre' rules={[{ required: true, message: "Ingresa un nombre", }]}>
                <Input placeholder='Nombre' className='custom-placeholder'/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='motivo' label='' rules={[{ required: true, message: "Selecciona motivo de tu consulta", }]} >
                <Select
                  popupClassName="dropdown-drpTipoConsulta"
                  suffixIcon={isTipoConsulta ? <ChevronUp /> : <ChevronDown />}
                  onDropdownVisibleChange={(open) => setIsTipoConsulta(open)}
                  multiline={true}
                  placeholder="Selecciona el motivo de tu consulta"
                  options={[
                    {
                      value: 'consulta',
                      label: 'Consulta',
                    },
                    {
                      value: 'sugerencia',
                      label: 'Sugerencia',
                    },
                    {
                      value: 'ayuda',
                      label: 'Necesito ayuda',
                    },
                    {
                      value: 'otro',
                      label: 'Otro motivo',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='correo' label='Correo' rules={[
                { required: true, message: "Ingresa un correo" },
                {
                  validator: validateEmail,
                }]}>
                <Input placeholder='Correo' className='custom-placeholder'/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='mensaje' label='Mensaje' rules={[{ required: true, message: "Ingresa un mensaje", }]}>
                <TextArea placeholder="Escribe tu mensaje..." rows={4} className='custom-placeholder'/>
              </Form.Item>
            </Col>
            <Col span={24} style={{ paddingBottom: 0 }}>
              <Form.Item valuePropName="checked" name='aceptaTerminos' 
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(),
                  },
                ]}>
                <Space size={8} align="start">
                <label>
                    <input type="checkbox" onchange={onChange}/>
                    <span class="checkmark"></span>
                </label>
                <Text className='texto-check-h'>He leído y acepto los <Link onClick={showModal} to={'#'} className='term-bold'>Términos y condiciones y la Política de privacidad </Link><Text style={{ color: '#ff411c', fontSize: 16 }}>*</Text></Text>
                </Space>
              </Form.Item> 
            </Col>
            <Col span={24}>
              <Form.Item>
                <Space size={8} align="start">
                <label>
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                </label>
                  <Text className='texto-check'>
                    Acepto recibir comunicaciones comerciales electrónicas y/o newsletters
                  </Text>
                </Space>
              </Form.Item>
            </Col>
            <Col span={24}>
                <Flex justify='center'>
                  <Button loading={formIsSubmiting}
                    className='btn-contacto'
                    type="primary"
                    htmlType="submit"
                    disabled={disabledSave}
                    >
                    Enviar mensaje
                  </Button>
                </Flex>
            </Col>
          </Form>
        </Row>
      </Card>
      <ModalTerminosCondiciones isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </>
  )
}

export default Formulario